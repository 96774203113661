import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import Footer from '../components/footer';

class Product extends Component {

    constructor(props){
        super(props);
        this.state = {
            data: [],
            loading: false
        }
    }

    componentDidMount() {
        var searchUrl = `https://cemedicament.ro/api/products.php`;
        this.setState({ loading: true });

        fetch(searchUrl,
            {
                method: "GET",
                headers: {
                    'Content-type': 'application/json'
                },
            }
        )
            .then(response => {
                return response.json();
            })
            .then(data => {
                this.setState({ data, loading: false });
            });
    }

    render() {

        return (

            <>
                <Helmet>
                    <title>Ce medicament? - Lista de medicamente</title>

                    <meta name="description" content="Singurul motor de cautare pentru medicamente" />
                    <meta name="robots" content="index, follow" />

                    <link rel="icon" href="/favicon.ico" />
                    <link rel="apple-touch-icon" href="/logo192.png" />

                    <script data-ad-client="ca-pub-2333940847398247" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>

                    {/* Open Graph / Facebook */}
                    <meta property="og:title" content="Ce medicament? - Lista de medicamente" />
                    <meta property="og:description" content="Singurul motor de cautare pentru medicamente" />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="https://cemedicament.ro/meds.jpg" />

                    {/* Twitter */}
                    <meta property="twitter:title" content="Ce medicament? - Lista de medicamente" />
                    <meta property="twitter:description" content="Singurul motor de cautare pentru medicamente" />
                    <meta property="twitter:image" content="https://cemedicament.ro/meds.jpg" />
                    <meta property="twitter:card" content="https://cemedicament.ro/meds.jpg" />
                </Helmet>

                <div id="root" className="uk-height-viewport uk-flex uk-flex-column">

                    <div className="uk-section uk-section-default">
                        <div className="uk-container uk-text-center">
                            <a href="/">
                                <img src="/logo.svg" width="100" height="" alt="" />
                            </a>
                            <h1 className="uk-heading-medium">
                                Lista de medicamente
                            </h1>

                            {this.state.loading && <div className="lds-facebook">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>}
                        </div>
                    </div>

                    {this.state.data ? (
                        <div className="uk-section uk-section-default">
                            <div className="uk-grid-small uk-child-width-1-3@s uk-flex-center uk-text-center" uk-grid="true">
                                {this.state.data.map((medicament, id) => (
                                    <div key={id} className="uk-container">
                                        <a href={'/medicament/' + medicament.id}>{medicament.name}</a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (   
                        <p></p>
                    )}
                </div>

                <Footer/>
            </>
        );
    }
}

export default Product;